const components = [
    'hamburger',
    'antispam',
    'emailreplace',
    'scroll',
    'togglecasestudy',
    'togglelang',
    'fixheader',
];

const App = {
    init() {
        components.forEach((component) => {
            let comp = require('components/' + component);
            comp.init();
        });
    },
};

module.exports = App;
