var didScroll;
var lastScrollTop = 0;
var delta = 0;
var st = $(this).scrollTop();

var $header = $('header');

function performActionForHeader() {
    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > 0) {
        // Scroll Down
        $header.addClass('nav-up');
    } else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
            $header.removeClass('nav-up');
        }
    }

    if (st > 0) {
        $header.addClass('header--small');
    } else {
        $header.removeClass('header--small');
    }

    lastScrollTop = st;
}

const Fixheader = {
    init: () => {
        $(window).scroll(function(event){
            didScroll = true;
        });

        performActionForHeader();

        setInterval(function() {
            if (didScroll) {
                st = $(this).scrollTop();

                performActionForHeader();

                didScroll = false;
            }
        }, 250);
    },
};

module.exports = Fixheader;
