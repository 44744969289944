const Togglelang = {
    init: () => {
        $('.js-toggle-lang').off().on('click', function(e) {
            e.preventDefault();

            $(this).parent('.header__langs').toggleClass('header__langs--show');
        });

        $(document).on('click', function (event) {
            if (!$(event.target).closest('.header__langs').length) {
                $('.header__langs').removeClass('header__langs--show');
            }
        });

        $(document).keydown(function(e) {
            if (e.keyCode == 27) {
                $('.header__langs').removeClass('header__langs--show');
            }
        });
    },
};

module.exports = Togglelang;
