const Togglecasestudy = {
    init: () => {
        $('.js-toggle-case-study').off().on('click', function(e) {
            e.preventDefault();

            $(this).parent('.case-studies__text').toggleClass('case-studies__text--show');
        });
    },
};

module.exports = Togglecasestudy;
