const Hamburger = {
    init: () => {
        $('.js-hamburger').off().on('click', function(e) {
            e.preventDefault();

            $('body').toggleClass('show-menu');
        });
    },
};

module.exports = Hamburger;
